// This returns a heirachical category list of all categories

import { createContext, useContext } from "react";
import useSWR from "swr";

const CategoryContext = createContext();
const headers = {
  "Cache-Control": "s-maxage=1, stale-while-revalidate=86400", // Edge caching
};

const fetcher = (url) => fetch(url, { headers }).then((res) => res.json());

// Recursive function to sort categories and their children
const sortCategories = (categories) => {
  if (!categories) return;

  // Sort categories based on the number of children
  categories.sort((a, b) => {
    const aChildrenCount = a.children ? a.children.length : 0;
    const bChildrenCount = b.children ? b.children.length : 0;
    return bChildrenCount - aChildrenCount || a.title.localeCompare(b.title);
  });

  // Recursively sort child categories
  categories.forEach((category) => {
    if (category.children) {
      sortCategories(category.children);
    }
  });
};

export const CategoryProvider = ({ children }) => {
  const url = `/api/category`;

  const { data, error } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
  });


  const buildCategoryTree = (flatCategories) => {
    const categoryMap = {};
    const tree = [];

    if (flatCategories) {
      // First pass: Initialize categories without children array
      flatCategories.forEach((category) => {
        categoryMap[category.id] = {
          title: category.name,
          slug: category.slug,
          href: `/products/category/${category.slug}`,
          parent: category.parent,
          url: `/products/category/${category.slug}`,
        };
      });

      // Second pass: Populate children where necessary
      flatCategories.forEach((category) => {
        if (category.parent === 0) {
          tree.push(categoryMap[category.id]);
        } else {
          const parentCat = categoryMap[category.parent];
          if (parentCat) {
            parentCat.children = parentCat.children || [];
            parentCat.children.push(categoryMap[category.id]);
          }
        }
      });

      sortCategories(tree);
    }

    return tree;
  };

  const memoizedCategories = buildCategoryTree(data);

  return (
    <CategoryContext.Provider value={memoizedCategories}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategories = () => useContext(CategoryContext);


// Export a separate function to build the hierarchical category structure
export const buildCategoryChildrenMap = () => {
  const url = `/api/category`;
  
  // Fetch the flat list of categories
  const { data: flatCategories, error } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
  });

  const categoryMap = {};

  if (!flatCategories) return categoryMap;

  // Initialize categoryMap with empty arrays for each category slug
  flatCategories.forEach((category) => {
    categoryMap[category.slug] = [];
  });

  // Populate the children into their parent category's array
  flatCategories.forEach((category) => {
    if (category.parent !== 0) {
      // If the category has a parent, find all parent categories and push this category's slug into their arrays
      flatCategories.forEach((parentCategory) => {
        if (category.parent === parentCategory.id) {
          categoryMap[parentCategory.slug].push(category.slug);
        }
      });
    }
  });

  console.log('categoryMap in CAT CONTEXT', categoryMap);
  return categoryMap;
};
