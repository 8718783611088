'use client';
import { createContext, useContext, useState, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import { debounce } from 'lodash';
import {useCategories, buildCategoryChildrenMap} from './CategoryContext';

const headers = {
    'Cache-Control': 's-maxage=1, stale-while-revalidate=86400' // Edge caching
};

const ProductContext = createContext();
const fetcher = url => fetch(url, { headers }).then(res => res.json());


export const ProductProvider = ({ children }) => {
  const url = `/api/product/getAll`;

  // Fetch all products
  const { data: products, error } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    shouldRetryOnError: false,
    revalidateIfStale: false,
  });

  // Use buildCategoryChildrenMap to get the category-to-children map
  const categoryChildrenMap = buildCategoryChildrenMap();

  // Set filters (for price, brand, etc.)
  const [filters, setFilters] = useState({
    price: { min: 0, max: Infinity },
    brand: '',
    connections: [],
    categorySlug: '',
    searchText: ''
  });

  // Loading state should reflect both products and categories being fetched
  const isLoading = !products || !categoryChildrenMap || Object.keys(categoryChildrenMap).length === 0;

  // Provide values in context
  const value = useMemo(() => ({
    products,
    isLoading,
    isError: error,
    filters,
    setFilters,
    categoryChildrenMap, // Category-to-children map provided here
  }), [products, error, filters, categoryChildrenMap, isLoading]);

  return (
    <ProductContext.Provider value={value}>
      {children}
    </ProductContext.Provider>
  );
};


export const useProductCategory = (categorySlug) => {
    const { products, categoryChildrenMap, isLoading: productsLoading, isError: productsError } = useProducts();
  
    useEffect(() => {
        console.log('*****************useProductCategory***********************')
      console.log('* useProductCategory - products:', products); // Check if products are being fetched
      console.log('* useProductCategory - categoryChildrenMap:', categoryChildrenMap); // Check if category children map exists
      console.log('* useProductCategory - categorySlug:', categorySlug); // Ensure correct category slug
    }, [products, categoryChildrenMap, categorySlug]);
  
    // Use the categoryChildrenMap to get the child categories for the given categorySlug
    const productsInCategory = useMemo(() => {
      if (products && categoryChildrenMap) {
        // Get the child categories for the given categorySlug
        const childCategories = categoryChildrenMap[categorySlug] || [];
        const allCategorySlugs = [categorySlug, ...childCategories]; // Include the parent category as well
  
        // Filter products that belong to the category or its children
        return products.filter(product =>
          product.categories.some(category => allCategorySlugs.includes(category.slug))
        );
      }
      return []; // Return an empty array if no products match
    }, [products, categoryChildrenMap, categorySlug]);
  
    return {
      productsData: productsInCategory,
      isLoading: productsLoading,
      isError: productsError
    };
  };


export const useProducts = () => useContext(ProductContext);





// export const useProductCategory = (categorySlug) => {
//     // Trigger the API call when categorySlug changes
//     const { data: categoryData, error: categoryError, isValidating: categoryLoading } = useSWR(
//       `/api/product/byCategory/${categorySlug}?status=publish`,
//       fetcher
//     );
  
//     const isLoading = categoryLoading;
//     const isError = categoryError;
  
//     const productsInCategory = useMemo(() => {
//       if (!isLoading && !isError && categoryData) {
//         return categoryData;  // Use API result if data exists
//       } 
//       return [];  // Return empty array as a fallback if loading, error, or no data
//     }, [categoryData, isLoading, isError]);
  
//     return {
//       productsData: productsInCategory,
//       isLoading,
//       isError,
//     };
//   };
  
  

// Get products from Woo API search
export const useSearchQueryData = (initialQuery) => {
    const [searchText, setSearchText] = useState(initialQuery?.query ?? '');
    const [debouncedSearch, setDebouncedSearch] = useState(searchText);

    const { filters, setFilters } = useContext(ProductContext);

    useEffect(() => {
        const handler = debounce(() => {
            setDebouncedSearch(searchText);
        }, 300);

        handler();
        return () => handler.cancel();
    }, [searchText]);

    const { data, error } = useSWR(
        debouncedSearch ? `/api/product/bySearch/${encodeURIComponent(debouncedSearch)}?status=publish` : null,
        fetcher,
        { revalidateOnFocus: false }
    );

    const filteredData = useMemo(() => {
        if (!data) return [];
        // return data;

        return data.filter(product => {
            const matchesPrice = product.price >= filters.price.min && product.price <= filters.price.max;
            const matchesBrand = !filters.brand || (product.custom_fields && product.custom_fields.brand === filters.brand);
            const matchesConnections = !filters.connections.length || (product.custom_fields && product.custom_fields.connections?.some(connection =>
                filters.connections.some(conn => conn in connection)
            ));
            const matchesCategory = !filters.categorySlug || product.categories.some(category => category.slug === filters.categorySlug);
            return matchesPrice && matchesBrand && matchesConnections && matchesCategory;
        });
    }, [data, filters]);

    const handleSearch = (event) => {
        const searchValue = event.target.value;
        setSearchText(searchValue);
        setFilters(prevFilters => ({ ...prevFilters, searchText: searchValue }));
    };

    return {
        handleSearch,
        resultList: filteredData ?? [],
        isLoading: !error && !data,
        isError: error
    };
};

// Get individual product info
export const useProductData = (productSlug) => {
    const { products } = useProducts();
    const url = `/api/product/${productSlug}?status=publish`;

    const productFromContext = products?.find(product => product.slug === productSlug);

    const { data, error } = useSWR(
        productFromContext ? null : url,
        fetcher,
        {
            revalidateOnFocus: false
        }
    );

    const productData = productFromContext || data;

    return {
        productData,
        isLoading: !error && !productData,
        isError: error
    };
};


